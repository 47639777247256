import { StateCreator } from 'zustand';
import { AuthorityTypeState } from './authorityTypes';

export const createAuthorityTypeSlice: StateCreator<AuthorityTypeState> = set => ({
  authorityTypeId: '',
  membershipData: null,
  setMembershipData: (data: any) =>
    set(() => ({
      membershipData: data,
    })),
  setAuthorityTypeId: (authorityTypeId: string) =>
    set(() => ({
      authorityTypeId,
    })),
  resetAuthorityTypeId: () =>
    set(() => ({
      authorityTypeId: '',
      membershipData: null,
    })),
});
